import React, { useEffect, useRef, useState } from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Text,
  Link,
  Container,
} from '@chakra-ui/react'

type FindmineClpPagesTasticProps = {
  data: {
    applicationID: string
    widgetID: string
    maxWidth: string
  }
}

const FindmineClpPages: React.FC<FindmineClpPagesTasticProps> = ({ data }) => {
  const containerRef = useRef(null)
  const { applicationID, widgetID, maxWidth } = data
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const handleClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleMouseDown = (e) => {
    if (!!e.target.href) {
      handleClick(e)
    }
  }

  const handleLookOverlayClick = (e) => {
    let targetElement = e.target
    while (targetElement && targetElement.tagName !== 'A') {
      targetElement = targetElement.parentElement
    }
    if (targetElement) {
      var hrefValue = targetElement.getAttribute('href')
    }
    const url = hrefValue
    if (url?.length) {
      e.preventDefault()
      e.stopPropagation()
      window.open(url, '_blank')
    }
  }

  const attachEvents = () => {
    setTimeout(() => {
      const container = containerRef.current
      const elemInterval = setInterval(() => {
        if (container.querySelectorAll('img') && container.querySelectorAll('img').length) {
          clearInterval(elemInterval)

          // find all images
          container.querySelectorAll('img').forEach((img) => {
            // Only apply the custom style to the images
            if (img.className?.trim() !== '') {
              img.style.width = 'auto'
              // img.style.objectFit = 'contain'
            }
          })

          const overlayContainers = document.querySelectorAll('.look-overlay')

          overlayContainers.forEach((overlayContainer) => {
            if (overlayContainer?.children?.length > 1) {
              const imageContainer = overlayContainer.children[0] as any
              imageContainer.style.width = '100%'
            }
          })

          // Select all links
          const allLinks = container.querySelectorAll('a')
          allLinks.forEach((link) => {
            link.style.color = '#222222'
          })

          if (container && container.querySelectorAll('a.quick-add')) {
            //const anchorElements = container.querySelectorAll('a.quick-add');
            const lookOverlayAnchors = container.querySelectorAll('.look-overlay a')
            // anchorElements.forEach((anchor) => {
            //   anchor.addEventListener('mousedown', handleMouseDown);
            // });
            lookOverlayAnchors.forEach((anchor) => {
              anchor.addEventListener('click', handleLookOverlayClick)
            })
            return () => {
              // anchorElements.forEach((anchor) => {
              //   anchor.removeEventListener('mousedown', handleMouseDown);
              // });
              lookOverlayAnchors.forEach((anchor) => {
                anchor.removeEventListener('click', handleLookOverlayClick)
              })
            }
          }
        }
      }, 100)
    }, 2000)
  }

  useEffect(() => {
    try {
      if (widgetID) {
        var script = document.createElement('script')
        script.onload = () => {
          const win: any = window
          setTimeout(() => {
            win.FindMineWidget.match({ application: applicationID, widget_id: widgetID })
            attachEvents()
          }, 1000)
        }
        script.src = '//findmine.com/static/js/fm.widget.dxl-multi-section-v4.js'
        document.head.appendChild(script)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <>
      <Container maxW={maxWidth + 'px'}>
        <div id="findmine-widget-app-container" ref={containerRef}></div>
      </Container>
    </>
  )
}

export default FindmineClpPages
